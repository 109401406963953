/* eslint-disable no-param-reassign */
import { BIMONTHLY_PERIOD, MONTHLY_PERIOD } from '@front/common/constants'
import { postFrequencySubscriptionSimulation } from '@front/common/endpoints'
import { Item, ItemType, Service, Subscription } from '@front/common/types'
import {
  booleanToYesNo,
  customerHasRelayPoint,
  getCustomFoodFromServices,
  serviceHasWetFood,
} from '@front/common/utils'
import Vue from 'vue'

interface Istate {
  subscriptions: Subscription[]
  activeSubscriptionFrequency: number
  hasSelectedFrequency: boolean
}

function bestSaleFrequency(
  monthlyQuantity: number,
  hasWetfood: boolean,
): number {
  const kibbleQuantityTreshold = 1500

  if (monthlyQuantity < kibbleQuantityTreshold) {
    return hasWetfood ? MONTHLY_PERIOD : BIMONTHLY_PERIOD
  }

  return MONTHLY_PERIOD
}

function bestSale({ services }: Subscription): number {
  const customFoods = getCustomFoodFromServices(services)
  const hasWetfood = customFoods.some((customFood: Service) =>
    serviceHasWetFood(customFood),
  )

  const monthlyQuantity = customFoods.reduce(
    (total: number, { items }: Service) => {
      const kibbleMonthlyAmount =
        items.find(({ type }: Item) => type === ItemType.kibble)?.quantity
          ?.monthly?.amount || 0

      return total + kibbleMonthlyAmount
    },
    0,
  )

  return bestSaleFrequency(monthlyQuantity, hasWetfood)
}

const initialState = (): Istate => ({
  subscriptions: [],
  activeSubscriptionFrequency: MONTHLY_PERIOD,
  hasSelectedFrequency: false,
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    get: (state) => state.subscriptions,
    hasSelectedFrequency: (state) => state.hasSelectedFrequency,
    active: (state) => {
      return state.subscriptions.find(
        ({ deliveryFrequency }) =>
          deliveryFrequency === state.activeSubscriptionFrequency,
      )
    },
  },
  mutations: {
    set(state, payload: Subscription[]) {
      if (!state.hasSelectedFrequency) {
        const { deliveryFrequency = MONTHLY_PERIOD } =
          payload.find(bestSale) || {}

        state.activeSubscriptionFrequency = deliveryFrequency
      }

      state.subscriptions = payload
    },
    setActiveSubscriptionFrequency(state, payload: number) {
      state.activeSubscriptionFrequency = payload
      state.hasSelectedFrequency = true
    },
  },
  actions: {
    async fetch({ commit, rootGetters }, payload) {
      const customer = rootGetters['customer/get']
      const promoCode = rootGetters['cart/getPromoCode']

      const subscriptions = await postFrequencySubscriptionSimulation(
        payload,
        promoCode?.code,
        customer.country,
        MONTHLY_PERIOD,
        booleanToYesNo(customerHasRelayPoint(customer)),
        Vue.prototype.$growthBook?.getFeatureValue('pricing-version'),
      )

      commit('set', subscriptions)
    },
  },
}
