import urlcat from 'urlcat'

import {
  KIBBLE,
  CONTEXT_CONDITION_OFFERS_OFFER,
  MONTHLY_PERIOD,
  WETFOOD_RATIO,
} from './constants'
import { CUMULATIVE_DISCOUNT_EFFECT, DISCOUNT_EFFECT } from './promo-codes'
import request, {
  DELETE,
  GET,
  POST,
  PUT,
  PaginatedResponse,
  Response,
  parseUrl,
} from './request'
import {
  Category,
  Customer,
  Discount,
  DiscountCollection,
  Item,
  Gift,
  NextOrder,
  PaymentMethod,
  Pet,
  PetItems,
  PickupPoint,
  Recipe,
  Service,
  Subscription,
  Transaction,
  Note,
  Call,
  Reason,
  Log,
  Article,
  Order,
  Commitment,
} from './types'
import { INSTAGRAM_API_URL } from './urls'
import { createDiscount, uid } from './utils'

export interface ItemsBulkPayload {
  update: Item[]
  delete: Item['id'][]
  create: Item[]
}

export interface ServicesBulkPayload {
  update: Service[]
  delete: Service['id'][]
  create: Service[]
}

export function itemWithServiceId(item, serviceId) {
  return { ...item, serviceId }
}

export function itemWithPetId(item, petId) {
  return { ...item, petId }
}

function addBaseWithPromotion(item: Item): Item {
  return {
    ...item,
    pricing: {
      ...item.pricing,
      baseWithPromotion: {
        amount:
          item.pricing.baseWithPromotion.amount || item.pricing.base.amount,
        currency: item.pricing.base.currency,
      },
    },
  }
}

function itemWithServicesInfo(item: Item, service: Service): Item {
  return {
    ...itemWithServiceId(itemWithPetId(item, service.petId), service.id),
    deliveryFrequency: service.deliveryFrequency,
  }
}

export function bindItemsDataFromService(service: Service): Service {
  return {
    ...service,
    items: service.items.map((item) => {
      return itemWithServicesInfo(item, service)
    }),
  }
}

export function serviceWithSubscriptionId(service, subscriptionId) {
  return {
    ...service,
    subscriptionId,
  }
}

export function itemsWithServiceIdFromSubscription(subscription) {
  return {
    ...subscription,
    services: subscription.services?.map(bindItemsDataFromService) || [],
  }
}

export async function getOffers({
  pet = {},
  wetfoodRatio = WETFOOD_RATIO.zero,
  keepRecipeCategory = false,
  deliveryFrequency = MONTHLY_PERIOD,
  petsNumber = 1,
  multiPetDiscountVersion = 0,
  pricingVersion = 0,
  contextCondition = CONTEXT_CONDITION_OFFERS_OFFER,
  isStandardized = true,
} = {}) {
  const createUrl = parseUrl('/v1/offers')

  interface response {
    highlightedItemIds: number[]
    items: Item[]
  }

  function mapFromApi(data) {
    return contextCondition === CONTEXT_CONDITION_OFFERS_OFFER
      ? { ...data, items: data.items.map(addBaseWithPromotion) }
      : data
  }

  const { data } = await request<Response<response>>({
    method: POST,
    url: createUrl,
    transformResponse: [mapFromApi], // FIXME: remove me after black friday is over
    body: {
      pet,
      options: {
        wetfoodRatio: wetfoodRatio || WETFOOD_RATIO.zero,
        keepRecipeCategory,
        deliveryFrequency,
        petsNumber,
        pricingVersion,
        contextCondition,
        isStandardized,
        multiPetDiscountVersion,
      },
    },
  })

  return data
}

export async function getArticle(articleId) {
  const createUrl = parseUrl('/v1/articles/:articleId', {
    articleId,
  })

  const { data } = await request<Response>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getBreeds(species) {
  const createUrl = parseUrl('/v1/breeds', {
    species,
  })

  const { data } = await request<Response<any[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getPetSimulation(pet) {
  const createUrl = parseUrl('/v1/pets/fill')

  const { data } = await request<Response>({
    method: POST,
    url: createUrl,
    body: pet,
  })

  return data
}

export async function getCategories() {
  const createUrl = parseUrl('/v1/categories')

  const { data } = await request<PaginatedResponse<Category[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getRecipes(species, type = KIBBLE) {
  const createUrl = parseUrl('/v1/recipes', {
    species,
    type,
  })

  const { data } = await request<Response<Recipe[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getPets(customerId) {
  const createUrl = parseUrl('/v1/pets', { customerId })

  const { data } = await request<Response<Pet[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function putPet(pet) {
  const createUrl = parseUrl('/v1/pets/:id', {
    id: pet.id,
  })

  const { data } = await request<Response>({
    method: PUT,
    url: createUrl,
    body: pet,
  })

  return data
}

export async function getPetNextBilling(petId: Pet['id']) {
  const createUrl = parseUrl('/v1/pets/{id}/next-billing/available', {
    id: petId,
  })

  const { data } = await request<Response>({
    method: GET,
    url: createUrl,
  })

  return data
}

interface updateBillingDateRequest {
  [petId: number]: {
    updatedBillingDate?: string
    updatedDeliveryDate?: string
  }
}

export async function putPetNextBilling(
  updateBillingDateRequest: updateBillingDateRequest,
) {
  const createUrl = parseUrl('/v1/pets/next-billing')

  const { data } = await request<Response>({
    method: PUT,
    url: createUrl,
    body: updateBillingDateRequest,
  })

  return data
}

export async function postPet(pet) {
  const createUrl = parseUrl('/v1/pets')

  const { data } = await request<Response<Pet>>({
    method: POST,
    url: createUrl,
    body: pet,
  })

  return data
}

export async function postRegistration(body) {
  const createUrl = parseUrl('/v1/registrations')

  interface response {
    customerId: number
    clientSecret?: string
    paypalReturnUrl?: string
    transaction: {
      priceExclusiveOfTax: any
      taxPrice: any
    }
  }

  const { data } = await request<Response<response>>({
    method: POST,
    url: createUrl,
    body,
  })

  return data
}

export async function getProducts() {
  const createUrl = parseUrl('/v1/products', {
    orderBy: 'rank_asc',
    isSaleable: 'yes',
  })

  const { data } = await request<Response<any[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function postPrm(body) {
  const createUrl = parseUrl('/v1/prm')

  const { data } = await request<Response>({
    method: POST,
    url: createUrl,
    body,
  })

  return data
}

export async function getSubscription(customerId) {
  const createUrl = parseUrl('/v1/subscriptions', {
    customerId,
  })

  const { data } = await request<Response<Subscription>>({
    method: GET,
    url: createUrl,
    transformResponse: [itemsWithServiceIdFromSubscription],
  })

  return data
}

export async function putSubscription(subscription: Subscription) {
  const createUrl = parseUrl('/v1/subscriptions/:id', {
    id: subscription.id,
  })

  const { data } = await request<Response>({
    method: PUT,
    url: createUrl,
    body: subscription,
  })

  return data
}

export async function getSubscriptionSimulation(subscription: Subscription) {
  const createUrl = parseUrl('/v1/subscriptions/simulation')

  const { data } = await request<Response<Subscription>>({
    method: POST,
    url: createUrl,
    body: subscription,
    transformResponse: [itemsWithServiceIdFromSubscription],
  })

  return data
}

export async function postItem(item: Item, subscriptionId: Subscription['id']) {
  const createUrl = parseUrl(
    '/v1/subscriptions/:subscriptionId/services/:serviceId/items',
    { serviceId: item.serviceId, subscriptionId },
  )

  const { data } = await request<Response<Service>>({
    method: POST,
    url: createUrl,
    body: item,
    transformResponse: [
      (data) =>
        itemWithPetId(itemWithServiceId(data, item.serviceId), item.petId),
    ],
  })

  return data
}

export async function putItem(item: Item, subscriptionId: Subscription['id']) {
  const createUrl = parseUrl(
    '/v1/subscriptions/:subscriptionId/services/:serviceId/items/:itemId',
    { serviceId: item.serviceId, itemId: item.id, subscriptionId },
  )

  const { data } = await request<Response<Service>>({
    method: PUT,
    url: createUrl,
    body: item,
    transformResponse: [
      (data) =>
        itemWithPetId(itemWithServiceId(data, item.serviceId), item.petId),
    ],
  })

  return data
}

export async function deleteItem(
  item: Item,
  subscriptionId: Subscription['id'],
) {
  const createUrl = parseUrl(
    '/v1/subscriptions/:subscriptionId/services/:serviceId/items/:itemId',
    { serviceId: item.serviceId, itemId: item.id, subscriptionId },
  )

  const { data } = await request<Response>({
    method: DELETE,
    url: createUrl,
  })

  return data
}

export async function postBulkItems(
  payload: ItemsBulkPayload,
  serviceId: Service['id'],
  subscriptionId: Subscription['id'],
) {
  const createUrl = parseUrl(
    '/v1/subscriptions/:subscriptionId/services/:serviceId/items/bulk',
    { serviceId, subscriptionId },
  )

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
    body: payload,
  })

  return data
}

const restrainDeliveryFrequency = (fn) => (value) => {
  const updatedValue = {
    ...value,
    // TODO: fix this restriction
    deliveryFrequency: Math.max(14, value.deliveryFrequency),
  }

  return fn(updatedValue)
}

export async function postBuildServices(petItems: PetItems, pets: Pet[]) {
  const createUrl = parseUrl('/v1/subscriptions/services/build')

  const { data } = await request<Response<Service[]>>({
    method: POST,
    url: createUrl,
    body: { petItems, pets },
    transformResponse: [
      (data) => data.map(restrainDeliveryFrequency(bindItemsDataFromService)),
    ],
  })

  return data
}

export async function postBulkServices(
  payload: ServicesBulkPayload,
  subscriptionId: Subscription['id'],
) {
  const createUrl = parseUrl(
    '/v1/subscriptions/:subscriptionId/services/bulk',
    { subscriptionId },
  )

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
    body: payload,
  })

  return data
}

export async function putService(service: Service) {
  const createUrl = parseUrl('/v1/subscriptions/services/:id', {
    id: service.id,
  })

  const { data } = await request<Response<Service>>({
    method: PUT,
    url: createUrl,
    body: service,
    transformResponse: [bindItemsDataFromService],
  })

  return data
}

export async function postService(service) {
  const createUrl = parseUrl('/v1/subscriptions/services')

  const { data } = await request<Response<Service>>({
    method: POST,
    url: createUrl,
    body: service,
    transformResponse: [bindItemsDataFromService],
  })

  return data
}

export async function deleteService(id: number) {
  const createUrl = parseUrl('/v1/subscriptions/services/:id', {
    id,
    useItem: 'yes',
  })

  const { data } = await request<Response>({
    method: DELETE,
    url: createUrl,
  })

  return data
}

export async function getCustomer(id) {
  const createUrl = parseUrl('/v1/customers/:id', {
    id,
    customerId: id,
  })

  const { data } = await request<Response<Customer>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function postTrialSubscriptionSimulation(
  services: Service[],
  promoCode = '',
  countryCode = 'FR',
  isPickupPoint = 'no',
  isStandardized = false,
  pricingVersion = 0,
) {
  const createUrl = parseUrl('/v1/subscriptions/trial-simulation', {
    promoCode,
    countryCode,
    isPickupPoint,
    isStandardized,
    pricingVersion,
  })

  const { data } = await request<Response<Subscription>>({
    method: POST,
    url: createUrl,
    body: services,
    transformResponse: [itemsWithServiceIdFromSubscription],
  })

  return data
}

export async function postFrequencySimulation({
  service,
  trialDeliveryFrequency,
  promoCode = '',
  numberOfPets = 1,
  pricingVersion = 0,
}: {
  service: Service
  trialDeliveryFrequency?: number
  promoCode?: string
  numberOfPets?: number
  pricingVersion?: number
}) {
  const createUrl = parseUrl(
    '/v1/subscriptions/services/frequency-simulation',
    { trialDeliveryFrequency, promoCode, numberOfPets, pricingVersion },
  )

  const { data } = await request<PaginatedResponse<Service[]>>({
    method: POST,
    url: createUrl,
    body: service,
    transformResponse: [
      (data) => {
        if (data?.errors) {
          return []
        }

        return {
          ...data,
          data: data.data.map(bindItemsDataFromService),
        }
      },
    ],
  })

  return data
}

export async function postFrequencySubscriptionSimulation(
  services: Service[],
  promoCode = '',
  countryCode = 'FR',
  deliveryFrequency = MONTHLY_PERIOD,
  isPickupPoint = 'no',
  pricingVersion = 0,
) {
  const createUrl = parseUrl('/v1/subscriptions/frequency-simulation', {
    promoCode,
    countryCode,
    deliveryFrequency,
    isPickupPoint,
    pricingVersion,
  })

  const { data } = await request<Response<Subscription[]>>({
    method: POST,
    url: createUrl,
    body: services,
  })

  return data
}

export async function getFrequency(
  monthlyKibblesQuantity,
  monthlyWetFoodQuantity,
) {
  const createUrl = parseUrl('/v1/frequency', {
    monthlyKibblesQuantity,
    monthlyWetFoodQuantity,
  })

  const { data } = await request<Response>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function postPromoCode(pets, code) {
  const createUrl = parseUrl('/v1/promocodes', { code })

  function mapFromApi(data) {
    const effects = data.effects.map((effect) => {
      if (
        effect.type === DISCOUNT_EFFECT ||
        effect.type === CUMULATIVE_DISCOUNT_EFFECT
      ) {
        return {
          ...effect,
          data: createDiscount(effect.data.value, effect.data.type),
        }
      }

      return effect
    })

    return {
      ...data,
      effects,
    }
  }

  const { data } = await request<Response>({
    method: POST,
    url: createUrl,
    body: { pets },
    transformResponse: [mapFromApi], // FIXME: remove when api returns a real discount for DISCOUNT effect
  })

  return data
}

// @FIXME: Used in back office with old call machine workflow.  Remove context
export async function getOrdersSimulationNext(context, { id: subscriptionId }) {
  const createUrl = parseUrl(
    '/v1/subscriptions/:subscriptionId/orders/simulation',
    {
      subscriptionId,
    },
  )

  const { data } = await request<Response>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getShippingFees(
  subscription: Subscription,
  isTrial = 'no',
  countryCode = 'FR',
  promoCode = '',
  isPickupPoint = 'no',
) {
  interface response {
    threshold: {
      amount: number
      currencyCode: string
    }
    fees: {
      amount: number
      currencyCode: string
    }
  }

  const createUrl = parseUrl('/v1/shipping/fees', {
    isTrial,
    countryCode,
    promoCode,
    isPickupPoint,
  })

  const { data } = await request<Response<response>>({
    method: POST,
    url: createUrl,
    body: subscription,
  })

  return data
}

export async function getCurrentDiscounts() {
  const createUrl = parseUrl('/v1/discounts/current')

  const { data } = await request<Response<DiscountCollection>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getSubscriptionDiscounts(subscriptionId: number) {
  const createUrl = parseUrl('/v1/subscriptions/:subscriptionId/discounts', {
    subscriptionId,
  })

  interface response {
    multiPetDiscounts: Discount[]
    activeMultiPetDiscount: number
  }

  const { data } = await request<Response<response>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getOrders({ limit = 8, customerId = null, offset = 0 }) {
  const createUrl = parseUrl('/v1/orders', { limit, customerId, offset })

  const { data } = await request<Response<any>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getOrder(orderId: number) {
  const createUrl = parseUrl('/v1/orders/:orderId', { orderId })

  const { data } = await request<Response<Order>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function putOrder(order) {
  const createUrl = parseUrl('/v1/orders/:orderId', {
    orderId: order.id,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body: order,
  })

  return data
}

export async function putOrderProducts({ orderId, products }) {
  const createUrl = parseUrl('/v1/orders/:orderId/products', {
    orderId,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body: products,
  })

  return data
}

export async function getPickupPoints({
  placeId = '',
  address = '',
  zipCode = '',
  city = '',
  countryCode = 'FR',
}) {
  const createUrl = parseUrl('/v1/pickup-points', {
    placeId,
    address,
    zipCode,
    city,
    country_code: countryCode,
  })

  const { data } = await request<Response<PickupPoint[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getCalls(customerId: number) {
  const createUrl = parseUrl(
    '/v1/calls',
    { customerID: customerId }, // FIXME: replace with `customerId`
  )

  const { data } = await request<Response<Call[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function postCall(payload: Call) {
  const createUrl = parseUrl('/v1/calls')

  const { data } = await request<Response<Call>>({
    method: POST,
    url: createUrl,
    body: payload,
  })

  return data
}

export async function postLogin(username: string, password: string) {
  const createUrl = parseUrl('/v1/auth/login')

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
    body: { username, password },
  })

  return data
}

export async function postLogout() {
  const createUrl = parseUrl('/v1/auth/logout')

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
  })

  return data
}

export async function getAuthStatus(id?: number) {
  const createUrl = parseUrl('/v1/auth/status', { customerId: id })

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function putCustomer(customer) {
  const createUrl = parseUrl('/v1/customers/:id', {
    id: customer.id,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body: customer,
  })

  return data
}

export async function getNextDelivery(id) {
  const createUrl = parseUrl('/v1/customers/:id/next-delivery', {
    id,
  })

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function putNextBilling(updatedDeliveryDate, id) {
  const createUrl = parseUrl('/v1/customers/:id/next-billing', {
    id,
  })
  const body = {
    updatedDeliveryDate,
  }

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body,
  })

  return data
}

export async function getAvailableNextBilling(petIds: Pet['id'][]) {
  const createUrl = parseUrl('/v1/orders/next-billing/available')

  interface response {
    nextBillingDate: Date
    minBillingDateLimit: Date
    maxBillingDateLimit: Date
    nonBillingBusinessDays: Date[]
    nextDeliveryDate: Date
    maxDeliveryDateLimit: Date
    minDeliveryDateLimit: Date
    nonBusinessDays: Date[]
  }

  const { data } = await request<Response<response>>({
    url: createUrl,
    method: POST,
    body: { petIds },
  })

  return data
}

export async function putDeliveryFrequency(updatedDeliveryFrequency, id) {
  // @TODO: update frequency
  const createUrl = parseUrl('/v1/customers/:id/delivery-frequency', {
    id,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body: { updatedDeliveryFrequency },
  })

  return data
}

export async function getPetAvailableDeliveryFrequency(id: Pet['id']) {
  const createUrl = parseUrl('/v1/pets/:id/delivery-frequency/available', {
    id,
  })

  interface response {
    availableFrequency: any[]
    deliveryFrequency: number
    maxFrequency: number
    minFrequency: number
  }

  const { data } = await request<Response<response>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function putPetDeliveryFrequency(
  updatedDeliveryFrequency: number,
  id: Pet['id'],
) {
  const createUrl = parseUrl('/v1/pets/:id/delivery-frequency', {
    id,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body: { updatedDeliveryFrequency },
  })

  return data
}

export async function getDiscounts(customerId) {
  const createUrl = parseUrl('/v1/discounts', {
    customerId,
  })

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function getPaymentMethod(id) {
  const createUrl = parseUrl('/v1/customers/:id/payment-methods', {
    id,
  })

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function postPaymentMethod(method, id) {
  const createUrl = parseUrl('/v1/customers/:id/payment-methods', {
    id,
  })

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
    body: { type: method, customerId: id },
  })

  return data
}

export async function getTransactions(id) {
  const createUrl = parseUrl('/v1/customers/:id/transactions', {
    id,
  })

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function getGlobalRecipes(species) {
  const createUrl = parseUrl('/v1/recipes', {
    species,
    type: KIBBLE,
  })

  const { data } = await request<Response<any>>({
    url: createUrl,
    method: GET,
  })

  return data
}

export async function getRelayPoints(queries) {
  const createUrl = parseUrl('/v1/pickup-points', queries)

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function deletePet(petId) {
  const stringifiedPetId = String(petId)
  const createUrl = parseUrl('/v1/pets/:petId', {
    petId: stringifiedPetId,
  })

  const { data } = await request<Response<any>>({
    method: DELETE,
    url: createUrl,
  })

  return data
}

export async function resubscribe(id, promoCode = '') {
  const createUrl = parseUrl('/v1/customers/:id/resubscribe', {
    id,
    promoCode,
  })

  const { data } = await request<Response<any>>({ method: PUT, url: createUrl })

  return data
}

export async function postResetPassword(email) {
  const createUrl = parseUrl('/v1/reset-password')

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
    body: { email },
  })

  return data
}

export async function putPassword(token, password) {
  const createUrl = parseUrl('/v1/customers/:token/password', {
    token,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    url: createUrl,
    body: { password },
  })

  return data
}

export async function getUpsellHighlightProduct(id) {
  const createUrl = parseUrl('/v1/customers/:id/upsell-highlight', {
    id,
  })

  const { data } = await request<Response<any>>({ url: createUrl, method: GET })

  return data
}

export async function getNextOrders(id) {
  const createUrl = parseUrl('/v1/orders/simulations', {
    customerId: id,
  })

  function mapFromApi(data) {
    return {
      ...data,
      data: data.data.map((order) => {
        return { ...order, id: uid() }
      }),
    }
  }

  const { data } = await request<PaginatedResponse<NextOrder[]>>({
    url: createUrl,
    method: GET,
    transformResponse: [mapFromApi],
  })

  return data
}

export async function deleteOrder(id, customerId) {
  const createUrl = parseUrl('/v1/orders/:id', {
    id,
    customerId,
  })

  const { data } = await request<Response>({
    url: createUrl,
    method: DELETE,
  })

  return data
}

export async function getLogs(id) {
  const createUrl = parseUrl('/v1/logs', {
    customerID: id,
  })

  const { data } = await request<Response<Log[]>>({
    url: createUrl,
    method: GET,
  })

  return data
}

export async function getSanteVetPetRates(petId) {
  const createUrl = parseUrl('/v1/santevet/rate', {
    petId,
  })

  interface Rates {
    amount: number
    applicationFee: number
    chosen: boolean
    id: number
    name: string
    options: any[]
  }

  const { data } = await request<Response<Rates[]>>({
    url: createUrl,
    method: GET,
  })

  return data
}

export async function postSanteVetQuotation(petId) {
  const createUrl = parseUrl('/v1/santevet/quotation', {
    petId,
  })

  interface IResponse {
    id: number
    uniqueId: string
    uuid: string
    urls: {
      frFr: string
    }
  }

  const { data } = await request<Response<IResponse>>({
    url: createUrl,
    method: POST,
  })

  return data
}

export async function getInstagraMedia(token) {
  const japhyInstagramUserId = '17841405929076517'

  // https://developers.facebook.com/docs/instagram-basic-display-api/reference/media#
  const url = urlcat(INSTAGRAM_API_URL, '/:userId/media', {
    userId: japhyInstagramUserId,
    fields: ['media_url'],
    access_token: token,
  })

  const { data } = await request<Response<any>>({
    url,
    method: GET,
    withCredentials: false,
  })

  return data
}

export async function postNewsLetterSubscription(email) {
  const createUrl = parseUrl('/v1/newsletter')

  const { data } = await request<Response>({
    url: createUrl,
    method: POST,
    body: { email },
  })

  return data
}

export async function putGift(gift: Gift) {
  const createUrl = parseUrl('/v1/gifts/:giftID', {
    giftID: gift.id,
  })

  const { data } = await request<Response<Gift>>({
    method: PUT,
    url: createUrl,
    body: gift,
  })

  return data
}

export async function postGift(gift: Gift) {
  const createUrl = parseUrl('/v1/gifts')

  const { data } = await request<Response<Gift>>({
    method: POST,
    url: createUrl,
    body: gift,
  })

  return data
}

export async function getGifts({ id, onlyActive = true }) {
  const createUrl = parseUrl('/v1/gifts', {
    customerID: id,
    onlyActive,
  })

  const { data } = await request<Response<Gift[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function deleteGift(giftID: number) {
  const createUrl = parseUrl('/v1/gifts/:giftID', {
    giftID,
  })

  await request<Response<void>>({
    method: DELETE,
    url: createUrl,
  })

  return giftID
}

export async function deleteDiscount(discountID: number) {
  const createUrl = parseUrl('/v1/discounts/:discountID', {
    discountID,
  })

  const { data } = await request<Response<void>>({
    method: DELETE,
    url: createUrl,
  })

  return data
}

export async function postDiscount(discount: Discount) {
  const createUrl = parseUrl('/v1/discounts')

  const { data } = await request<Response<Discount>>({
    method: POST,
    url: createUrl,
    body: discount,
  })

  return data
}

export async function getPaymentMethods(id: number) {
  const createUrl = parseUrl('/v1/customers/:id/payment-methods', {
    id,
    customerId: id, // FIXME: This needs to be removed
  })

  const { data } = await request<Response<PaymentMethod>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getTransactionsPerCustomer(id: number) {
  const createUrl = parseUrl('/v1/customers/:id/transactions', {
    id,
    customerId: id, // FIXME: This needs to be removed
  })

  const { data } = await request<Response<Transaction[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function putRetryTransaction(transactionID) {
  const createUrl = parseUrl('/v1/transactions/:transactionID/retry', {
    transactionID,
    mode: 'redshell',
  })

  const { data } = await request<Response<Transaction[]>>({
    method: PUT,
    url: createUrl,
  })

  return data
}

export async function getNotes(id: number) {
  const createUrl = parseUrl('/v1/notes', {
    customerID: id, // FIXME: This needs to be removed
  })

  const { data } = await request<Response<Note[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function putNote(note: Note) {
  const createUrl = parseUrl('/v1/notes/:noteID', {
    noteID: note.id,
  })

  const { data } = await request<Response<Note>>({
    method: PUT,
    body: note,
    url: createUrl,
  })

  return data
}

export async function postNote(note: Note) {
  const createUrl = parseUrl('/v1/notes')

  const { data } = await request<Response<Note>>({
    method: POST,
    body: note,
    url: createUrl,
  })

  return data
}

export async function putCustomerUnsubscribe({
  id,
  payload,
  bypassSchedule = true,
  callId,
}) {
  const createUrl = parseUrl('/v1/customers/:id/unsubscribe', {
    id,
    bypassSchedule,
    callId,
  })

  const { data } = await request<Response<any>>({
    method: PUT,
    body: payload,
    url: createUrl,
  })

  return { data, bypassSchedule }
}

export async function getReasons() {
  const createUrl = parseUrl('/v1/reasons')

  const { data } = await request<Response<Reason[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getArticles(isSaleable: boolean) {
  const createUrl = parseUrl('/v1/articles', {
    isSaleable,
  })

  const { data } = await request<Response<Article[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function getDelivery() {
  const createUrl = parseUrl('/v1/delivery', {
    country: 'FR',
  })

  const { data } = await request<Response<any>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function postTryPayment(subscriptionId) {
  const createUrl = parseUrl('/v1/subscriptions/:subscriptionId/try-payment', {
    subscriptionId,
  })

  const { data } = await request<Response<any>>({
    method: POST,
    url: createUrl,
  })

  return data
}

export async function getCustomerCommitments(id) {
  const createUrl = parseUrl('/v1/customers/:id/commitments/current', {
    id,
  })

  const { data } = await request<Response<Commitment[]>>({
    method: GET,
    url: createUrl,
  })

  return data
}

export async function postCustomerCommitment(id, commitmentId) {
  const createUrl = parseUrl(
    '/v1/customers/:id/commitment/make/:commitmentId',
    {
      id,
      commitmentId,
    },
  )
  const { data } = await request<Response<Commitment>>({
    method: POST,
    url: createUrl,
  })

  return data
}

export async function postCustomerCommitmentCancel(id) {
  const createUrl = parseUrl('/v1/customers/:id/commitment/cancel', {
    id,
  })
  const { data } = await request<Response<Commitment>>({
    method: POST,
    url: createUrl,
  })

  return data
}
