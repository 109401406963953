/* eslint-disable no-param-reassign */
import { postFrequencySimulation } from '@front/common/endpoints'
import { Pet, Service } from '@front/common/types'
import { getServicesByPetId } from '@front/common/utils'
import Vue from 'vue'

interface Istate {
  servicesByPet: {
    [petId: number]: Service[]
  }
}

const initialState = (): Istate => ({
  servicesByPet: {},
})

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getByPetId: (state) => {
      return (petId: Pet['id']) => {
        return state.servicesByPet[petId]
      }
    },
  },
  mutations: {
    set(state, payload) {
      state.servicesByPet = payload
    },
  },
  actions: {
    async fetch({ commit, rootGetters }, services: Service[]) {
      const trialSubscription = rootGetters['trial/get']
      const petsAmount = rootGetters['pets/get'].length
      const promoCode = rootGetters['cart/getPromoCode']

      const servicesByPet = await services.reduce(async (acc, service) => {
        const [firstServiceByPetId] = getServicesByPetId(
          trialSubscription.services,
          service.petId,
        )

        const { data } = await postFrequencySimulation({
          service,
          trialDeliveryFrequency: firstServiceByPetId.deliveryFrequency,
          promoCode: promoCode?.code,
          numberOfPets: petsAmount,
          pricingVersion:
            Vue.prototype.$growthBook?.getFeatureValue('pricing-version'),
        })

        return {
          ...(await acc),
          [service.petId]: data,
        }
      }, {})

      commit('set', servicesByPet)
    },
  },
}
