<template>
  <label
    :class="[
      $style.wrapper,
      {
        [$style.grey]: variant === 'grey',
        [$style.yellow]: variant === 'yellow',
        [$style.black]: variant === 'black',
        [$style.blue]: variant === 'blue',
        [$style.green]: variant === 'green',
      },
    ]"
  >
    <Typography :variant="TYPOGRAPHY_TYPES.bodySmallBold">
      <slot />
    </Typography>
  </label>
</template>

<script>
import { defineComponent } from 'vue-demi'

import { Typography, TYPOGRAPHY_TYPES } from '.'

export default defineComponent({
  components: { Typography },
  props: {
    variant: {
      type: String,
      default: 'grey',
    },
  },
  computed: {
    TYPOGRAPHY_TYPES: () => TYPOGRAPHY_TYPES,
  },
})
</script>

<style lang="scss" module>
$color-green-christmas: #385b36;

.wrapper {
  background: grey;
  padding: 5px $ds-spacing-mobile-extra-small;
  border-radius: 5px;
  display: inline-flex;

  &.grey {
    background: $ds-color-black-borders;
  }

  &.yellow {
    background: $ds-color-yellow-dark;
  }

  &.black {
    background-color: $ds-color-black;
    color: $ds-color-white;
  }

  &.blue {
    background-color: $ds-color-blue-dark;
  }

  &.green {
    background-color: $color-green-christmas;
    color: $ds-color-white;
  }
}
</style>
