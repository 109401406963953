/* eslint-disable camelcase */
import { Item, ItemType } from '@front/common/types'

export const DATALAYER_ANSWER_EVENT = 'answer'
export const DATALAYER_BEGIN_ANSWER_EVENT = 'begin_answer'
export const DATALAYER_RESUME_ANSWER_EVENT = 'resume_answer'
export const DATALAYER_RESTART_ANSWER_EVENT = 'restart_answer'
export const DATALAYER_FINISH_ANSWER_EVENT = 'finish_answer'
export const DATALAYER_VIEW_CART_EVENT = 'view_cart'
export const DATALAYER_BEGIN_CHECKOUT_EVENT = 'begin_checkout'
export const DATALAYER_PURCHASE_EVENT = 'purchase'
export const DATALAYER_NEXT_STEP_ANSWER = 'next_step'
export const DATALAYER_USER_ACCES_MEMBER_AREA = 'user_access_member_area'

export interface DataLayerProduct {
  item_id: string | number
  item_name: string
  item_category: string
  currency: string
  price: number
  quantity: number
}

export function itemToDataLayerProductFactory({
  id,
  translationKeys,
  imageUrls,
  type,
  pricing,
  quantity,
  recipe,
}: Item): DataLayerProduct {
  const titleByItemType = {
    [ItemType.kibble]: recipe?.marketLabel,
    [ItemType.wetfood]: translationKeys?.title || imageUrls.legacyTitle,
    [ItemType.upsell]: translationKeys?.title || imageUrls.legacyTitle,
  }

  return {
    item_id: id,
    item_name: titleByItemType[type],
    item_category: type,
    currency: 'EUR',
    price: pricing.base.amount,
    quantity,
  }
}

export function pushDataLayer(data) {
  if (process.env.APP_ENV !== 'production') {
    console.log(
      '%c Datalayer',
      'background: #68b6eb; color: white; padding: 2px 5px; padding-left: 0px; border-radius: 3px',
      data,
    )
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export function memberAreaUserAccessDataLayerEvent() {
  pushDataLayer({
    event: DATALAYER_USER_ACCES_MEMBER_AREA,
  })
}

export function profileBuilderDataLayerEvent({
  question,
  answer,
  petIndex,
  flowId,
}): void {
  pushDataLayer({
    event: DATALAYER_ANSWER_EVENT,
    question,
    answer,
    flowId,
    funnelNumber: Number(petIndex) + 1,
  })
}

export function profileBuilderBeginDataLayerEvent(flowId): void {
  pushDataLayer({
    event: DATALAYER_BEGIN_ANSWER_EVENT,
    flowId,
  })
}

export function profileBuilderResumeDataLayerEvent(flowId): void {
  pushDataLayer({
    event: DATALAYER_RESUME_ANSWER_EVENT,
    flowId,
  })
}
export function profileBuilderRestartDataLayerEvent(flowId): void {
  pushDataLayer({
    event: DATALAYER_RESTART_ANSWER_EVENT,
    flowId,
  })
}

export function profileBuilderFinishDataLayerEvent(flowId): void {
  pushDataLayer({
    event: DATALAYER_FINISH_ANSWER_EVENT,
    flowId,
  })
}

function commonEcommerceFields(
  dataLayerProducts: DataLayerProduct[],
  promocode = '',
) {
  const value = dataLayerProducts.reduce((acc, { price }) => {
    return price + acc
  }, 0)

  return {
    value,
    currency: 'EUR',
    coupon: promocode,
    items: dataLayerProducts,
  }
}

export function viewCartDataLayerEvent(
  dataLayerProducts: DataLayerProduct[],
  promocode: string,
): void {
  pushDataLayer({
    event: DATALAYER_VIEW_CART_EVENT,
    ecommerce: commonEcommerceFields(dataLayerProducts, promocode),
  })
}

export function beginCheckoutDataLayerEvent(
  dataLayerProducts: DataLayerProduct[],
  promocode: string,
): void {
  pushDataLayer({
    event: DATALAYER_BEGIN_CHECKOUT_EVENT,
    ecommerce: commonEcommerceFields(dataLayerProducts, promocode),
  })
}

export function purchaseDataLayerEvent(
  dataLayerProducts: DataLayerProduct[],
  transactionId: number, // FIXME: not the real transaction Id but customerId here
  tax: number,
  promocode: string,
  paymentMethod: any,
): void {
  const ecommerce = {
    transactionId,
    tax,
    customer_id: transactionId,
    payment_method: paymentMethod,
    ...commonEcommerceFields(dataLayerProducts, promocode),
  }

  pushDataLayer({
    event: DATALAYER_PURCHASE_EVENT,
    ecommerce,
  })
}

export function viewProductDataLayerEvent(
  dataLayerProduct: DataLayerProduct,
): void {
  pushDataLayer({
    event: 'view_item',
    ecommerce: {
      items: [dataLayerProduct],
    },
  })
}

export function addToCartDataLayerEvent(
  dataLayerProduct: DataLayerProduct[],
): void {
  pushDataLayer({
    event: 'add_to_cart',
    ecommerce: {
      items: dataLayerProduct,
    },
  })
}

export function modifyFromCartDataLayerEvent(
  dataLayerProduct: DataLayerProduct[],
): void {
  pushDataLayer({
    event: 'modify_from_cart',
    ecommerce: {
      items: dataLayerProduct,
    },
  })
}

export function removeFromCartDataLayerEvent(
  dataLayerProduct: DataLayerProduct[],
): void {
  pushDataLayer({
    event: 'remove_from_cart',
    ecommerce: {
      items: dataLayerProduct,
    },
  })
}

export function addToSubscriptionDataLayerEvent(
  dataLayerProduct: DataLayerProduct,
): void {
  pushDataLayer({
    event: 'add_to_subscription',
    ecommerce: {
      items: [dataLayerProduct],
    },
  })
}

export function modifyInSubscriptionDataLayerEvent(
  dataLayerProduct: DataLayerProduct,
): void {
  pushDataLayer({
    event: 'modify_in_subscription',
    ecommerce: {
      items: [dataLayerProduct],
    },
  })
}

export function deleteInSubscriptionDataLayerEvent(
  dataLayerProduct: DataLayerProduct,
): void {
  pushDataLayer({
    event: 'delete_in_subscription',
    ecommerce: {
      items: [dataLayerProduct],
    },
  })
}

export function virtualPageViewDataLayerEvent(
  url: string,
  title: string,
): void {
  pushDataLayer({
    event: 'virtual_page_view',
    pageUrl: url,
    pageTitle: title,
  })
}
