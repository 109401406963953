const TYPOGRAPHY_TYPES = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  bodyLarge: 'bodyLarge',
  bodyRegular: 'bodyRegular',
  bodyBold: 'bodyBold',
  bodySmallRegular: 'bodySmallRegular',
  bodyExtraSmallRegular: 'bodyExtraSmallRegular',
  bodySmallBold: 'bodySmallBold',
  bodyExtraSmallBold: 'bodyExtraSmallBold',
  linkRegular: 'linkRegular',
  linkSmall: 'linkSmall',
  linkBold: 'linkBold',
  button: 'button',
  handwritten: 'handwritten',
  lineThrough: 'lineThrough',
  lineThroughSmall: 'lineThroughSmall',
}

export default TYPOGRAPHY_TYPES
